import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Kapcsolat`}</h1>
    <h2>{`Elérhetőségeink`}</h2>
    <h3>{`Telephelyünk (állatotthon)`}</h3>
    <p>{`Budapest XVII.ker. Csordakút utca 138634/1 hrsz.`}<br parentName="p"></br>{`
`}{`(FIGYELEM! Állattal érkezőknek minden esetben előre be kell jelentkezni telefonon!)`}</p>
    <h3>{`Nyitvatartás`}</h3>
    <p>{`Hétfőtől szombatig 9:30-15:30-ig, vasárnap 10-14 óra között.`}<br parentName="p"></br>{`
`}{`Minden szombat nyílt nap; ilyenkor szeretettel várunk minden érdeklődőt, önkéntest, kutyázni vágyó állatbarátot!`}</p>
    <h3>{`Postázási címünk levelek esetén`}</h3>
    <p>{`1656 Budapest, Pf. 100.`}</p>
    <h3>{`Postázási címünk csomagok esetén`}</h3>
    <p>{`1171 Budapest, Csordakút utca vége`}</p>
    <h3>{`Számlázási címünk`}</h3>
    <p>{`1171 Budapest, Bördöce utca 13.`}</p>
    <h3>{`Telefonszámaink`}</h3>
    <p>{`Kérjük, válassza ki, milyen ügyben keres minket és a linkre kattintva megtalálja annak az önkéntesnek a telefonszámát, aki az adott ügyben segíteni tud. Mivel nem minden önkéntesünk tartózkodik az állatotthonban, illetve minden önkéntesnek más a feladata, kérjük, hogy kizárólag az adott ügyhöz kijelölt számot hívják!`}</p>
    <ul>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=12" target="_blank" rel="noreferrer">
  Kutyát/macskát/egyéb állatot szeretnék örökbefogadni az állatotthonból
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=13" target="_blank" rel="noreferrer">
  Kutyát szeretnék beadni az állatotthonba
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=14" target="_blank" rel="noreferrer">
  Macskát szeretnék beadni az állatotthonba
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=15" target="_blank" rel="noreferrer">
  Egyéb állatot szeretnék beadni az állatotthonba
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=1014" target="_blank" rel="noreferrer">
  Lovakkal kapcsolatos kérdések, észrevételek
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=16" target="_blank" rel="noreferrer">
  Elveszett/Talált állatot szeretnék felvetetni az internetes nyilvántartásba
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=17" target="_blank" rel="noreferrer">
  Az alapítvány honlapjára szeretnék feladni lakossági hirdetést
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=18" target="_blank" rel="noreferrer">
  Adományokkal kapcsolatban érdeklődöm
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=19" target="_blank" rel="noreferrer">
  Sajtóval kapcsolatos ügyben érdeklődöm / interjút szeretnék
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=20" target="_blank" rel="noreferrer">
  Állatkínzással kapcsolatos feljelentéshez kérek segítséget /
  (szak)dolgozathoz kérek segítséget
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=21" target="_blank" rel="noreferrer">
  Rendezvényre szeretném meghívni az állatotthont
        </a>
      </li>
      <li parentName="ul">
        <a href="http://www.noeallatotthon.hu/index.php?pid=2&cid=22" target="_blank" rel="noreferrer">
  Gyerekcsoporttal szeretnék ellátogatni az állatotthonba
        </a>
      </li>
    </ul>
    <h3>{`Bankszámlaszámunk:`}</h3>
    <p><strong parentName="p">{`11710002-20083777`}</strong></p>
    <h4>{`Külföldről történő utaláshoz`}</h4>
    <p>{`IBAN: HU62 1171 0002 2008 3777 0000 0000`}<br parentName="p"></br>{`
`}{`SWIFT (BIC): OTPV-HU-HB`}<br parentName="p"></br>{`
`}{`OTP Bank / Budapest, 1102 Kőrösi Csoma sétány 6.`}</p>
    <h3>{`EURO számlánk:`}</h3>
    <p>{`11763103-13106883`}</p>
    <h4>{`Külföldről történő utaláshoz:`}</h4>
    <p>{`IBAN: HU15 1176 3103 1310 6883 0000 0000`}<br parentName="p"></br>{`
`}{`SWIFT (BIC): OTPVHUHB`}<br parentName="p"></br>{`
`}{`OTP Bank / Budapest, 1102 Kőrösi Csoma sétány 6.`}</p>
    <h3>{`Adószámunk:`}</h3>
    <p><strong parentName="p">{`18169696-1-42`}</strong></p>
    <h2>{`Adózással kapcsolatos információk`}</h2>
    <p><strong parentName="p">{`2010. január 1. napjától változott a jogszabály, kérjük olvassa el figyelmesen!`}</strong></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Magánszemélyeket érintő változás: 2010. januar 1. napjától megszűnik a közcélú adományok kedvezménye (CXVII. szja. tv. 35-38. §)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Társasági adó hatálya alá tartozó társaságok esetében a közhasznú szervezettel kötött tartós adományozási szerződés keretében adott támogatás, juttatás, térítés nélkül adott eszköz, nyújtott szolgáltatás bekerülési értekének 20%-a csökkenti az adózás előtti eredményt. LXXXI. társasági adótörvény 7 § z) pontja/`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`FIGYELEM!`}</strong>{` Kérjük, csekken történő befizetés esetén adatait tollal, jól olvashatóan írja rá a csekkre és semmiképpen ne használjon pecsétet, mert a banki fénymásolatokon a pecsét olvashatatlanná válhat! Amennyiben más adja fel utalását, a megjegyzésben feltétlenül jelezze, hogy kinek az adománya került befizetésre, mert csak ebben az esetben állíthatunk ki adóigazolást az utaló személyétől különböző névre!`}</p>
    <p>{`Megértésüket köszönjük!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      